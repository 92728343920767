import { useState } from "react";

export default (url) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Using Try Catch Block
  // const doFetch = async (payload) => {
  //   setIsLoading(true);
  //   let responseData;
  //   try {
  //     const response = await fetch(url, payload);
  //     responseData = await response.json();
  //     setIsLoading(false);
  //   } catch (err) {
  //     setIsLoading(false);
  //     setError("The code you entered is invalid");
  //   }
  //   return responseData;
  // };

  // Using Try Catch Alternative
  const doFetch = async (payload) => {
    setIsLoading(true);
    let responseData;

    const response = await fetch(url, payload).catch((err) => {
      setIsLoading(false);
      setError("The code you entered is invalid");
    });
    if (response) {
      responseData = await response.json();
      setIsLoading(false);
    }

    return responseData;
  };

  return [isLoading, error, setError, doFetch];
};
