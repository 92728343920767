import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../context/context";

const Breadcrumb = (props) => {
  const location = useLocation();

  const {
    state: {
      layout: { allLayouts },
    },
    dispatch,
  } = useContext(Context);

  const [layoutfields, setLayoutFields] = useState(JSON.parse(localStorage.getItem("allLayouts")) || allLayouts);
  // This useEffect will run on first load to check what is in localstorage and populate our context with it
  useEffect(() => {
    const allLayouts = localStorage.getItem("allLayouts");

    if (allLayouts) {
      dispatch({ type: "UPDATE_ALL_LAYOUTS", data: JSON.parse(allLayouts) });
      setLayoutFields(allLayouts);
    }
    // Scroll to top on page load
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLayoutFields(allLayouts);
    localStorage.setItem("allLayouts", JSON.stringify(allLayouts));
  }, [allLayouts]);

  if (location.pathname.replace(/\\|\//g, "").toLowerCase() !== "congratulations" && location.pathname.replace(/\\|\//g, "").toLowerCase() !== "confirmation") {
    return (
      <div className="col-12 px-0 mb-4">
        <ul className="nav nav-pills nav-fill">
          {allLayouts.map((layout) => {
            return (
              <li className="nav-item px-2 py-1" key={layout.title}>
                <Link className={location.pathname.replace(/\\|\//g, "").toLowerCase() === layout.slug.toLowerCase() ? "nav-link active" : layout.completed ? "nav-link completed" : "nav-link disabled"} to={layout.slug}>
                  {layout.title}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else {
    return null;
  }
};

export default Breadcrumb;

// Router hooks reference
// import { Link, useParams, useHistory, useLocation } from "react-router-dom";
//  const params = useParams();
// const history = useHistory();
// console.log(location.pathname.replace(/\\|\//g, ""));
