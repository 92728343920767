import React, { useReducer, useContext } from "react";
import combineReducers from "react-combine-reducers";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { Context } from "./context/context";
import { AuthContext } from "./context/auth-context";
import { DataContext } from "./context/data-context";
import { LayoutContext } from "./context/layout-context";
import { useAuth } from "./hooks/useAuth";

//Auth Reducer
import AuthReducer from "./reducers/authReducer";
//User Reducer
import DataReducer from "./reducers/dataReducer";
//Layout Reducer
import LayoutReducer from "./reducers/layoutReducer";

import Login from "./layouts/Login";
import Congratulations from "./layouts/Congratulations";
import Welcome from "./layouts/Welcome";
import Priority from "./layouts/Priority";
import Selection from "./layouts/Selection";
import Finance from "./layouts/Finance";
import Details from "./layouts/Details";
import PartExchange from "./layouts/PartExchange";
import Summary from "./layouts/Summary";
import Confirmation from "./layouts/Confirmation";
import Layout from "./layouts/Layout";

const App = () => {
  const AuthCtx = useContext(AuthContext);
  const DataCtx = useContext(DataContext);
  const LayoutCtx = useContext(LayoutContext);

  const [rootReducerCombined, rootContextCombined] = combineReducers({
    auth: [AuthReducer, AuthCtx],
    data: [DataReducer, DataCtx],
    layout: [LayoutReducer, LayoutCtx],
  });
  const [state, dispatch] = useReducer(rootReducerCombined, rootContextCombined);
  // const {
  //   layout: { allLayouts },
  // } = state;
  const { isAuthenticated } = useAuth(state, dispatch);

  let routes;
  if (isAuthenticated) {
    routes = (
      <>
        <Route exact path="/">
          <Redirect to="/congratulations" />
        </Route>
        <Route exact path="/congratulations">
          <Congratulations />
        </Route>
        <Route exact path="/welcome">
          <Welcome />
        </Route>
      <Route exact path="/priority">
          <Priority />
      </Route>
        <Route exact path="/selection">
          <Selection />
        </Route>
        <Route exact path="/finance">
          <Finance />
        </Route>
        <Route exact path="/part-exchange">
          <PartExchange />
        </Route>
        <Route exact path="/details">
          <Details />
        </Route>
        <Route exact path="/summary">
          <Summary />
        </Route>
        <Route exact path="/confirmation">
          <Confirmation />
        </Route>

        <Route exact path="/login">
          <Redirect to="/welcome" />
        </Route>
        {/*   <Redirect to="/account" /> */}
      </>
    );
  } else {
    routes = (
      <>
        <Route exact path="*">
          <Login />
        </Route>
        {/* <Redirect to="/login" /> */}
      </>
    );
  }
  return (
    <Router>
      <Context.Provider
        value={{
          state,
          dispatch,
        }}
      >
        <Layout>
          <Switch>{routes}</Switch>
        </Layout>
      </Context.Provider>
    </Router>
  );
};
export default App;
