import { useEffect } from "react";

export const useAuth = (state, dispatch) => {
  const {
    auth: { isAuthenticated },
  } = state;

  // Keeps the user logged in if localstorage contains the user data obtained from the login
  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("userToken"));
    if (storedData) {
      // We persist the login and keep the state defined with the values userID and token, which in turn keeps the routes alive
      dispatch({
        type: "LOGIN",
        payload: { response: storedData },
      });
    } else {
      dispatch({
        type: "LOGOUT",
      });
    }
  }, [dispatch]);

  return { isAuthenticated };
};
